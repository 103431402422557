import React from "react"

function Close() {
  return (
    <svg
      id="Group_4"
      data-name="Group 4"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20.163"
      viewBox="0 0 20 20.163"
    >
      <rect
        id="Rectangle"
        width="26.009"
        height="2.276"
        transform="translate(1.609 0.163) rotate(45)"
        fill="#fff"
      />
      <rect
        id="Rectangle_Copy"
        data-name="Rectangle Copy"
        width="26.009"
        height="2.276"
        transform="translate(0 18.391) rotate(-45)"
        fill="#fff"
      />
    </svg>
  )
}

export default Close
