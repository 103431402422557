import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql, navigate } from "gatsby"

import "../assets/scss/main.scss"

import Menu from "../components/menu"
import Header from "../components/header"
import Footer from "../components/footer/footer"
import Contact from "../components/contact/contact"
import LoadingScreen from "../components/loadingScreen"

const Layout = ({ children, location }) => {
  
  const [showMenu, setShowMenu] = useState(false)
  const [hide, setHide] = useState(true)

  return (

    <StaticQuery
      query={graphql`
            query SiteTitleQuery {
              site {
                siteMetadata {
                  title
                }
              }
              wp {
                siteOptions {
                  acf {
                    logo {
                      sourceUrl
                    }
                    mobileLogo {
                      sourceUrl
                    }
                  }
                }
              }
              wpMenu(locations: { eq: PRIMARY_NAVIGATION }) {
                count
                id
                databaseId
                name
                slug
                menuItems {
                  nodes {
                    id
                    databaseId
                    title
                    uri
                    cssClasses
                    description
                    label
                    linkRelationship
                    target
                    parentId
                  }
                }
              }
            }
          `}
      render={(data) => {
        return (
          <>
            <div className="layout-wrapper">
              <Header
                data={data}
                showMenu={showMenu}
                setShowMenu={setShowMenu}
                setHide={setHide}
              />

              <Contact hide={hide} setHide={setHide} />
              <main
                className={
                  showMenu
                    ? "main-layout showing_menu"
                    : !hide
                      ? "main-layout showing_contact"
                      : "main-layout"
                }
              >
                {children}
              </main>
              <Menu
                links={data?.wpMenu?.menuItems?.nodes}
                setShowMenu={setShowMenu}
                setHide={setHide}
                showMenu={showMenu}
              />
              <Footer />
            </div>
          </>
        )
      }}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
