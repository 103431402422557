import React, { useEffect, useState, useCallback } from "react"
import Logo from "./images/Logo"
import Hambuger from "./images/Hambuger"
import StickyLogo from "./images/StickyLogo"
import { Link, navigate } from "gatsby"
import useWindowDimensions from "../../hooks/useWindowDimensions"

const Header = (props) => {
  const { width } = useWindowDimensions()
  const [sticky, setSticky] = useState(false)
  const { hide, setHide, showMenu, setShowMenu, data, handleCheckNav } = props
  const handleSticky = useCallback(() => {
    const scrollTop = window.scrollY
    if (scrollTop > 0) {
      setSticky(true)
    } else {
      setSticky(false)
    }
  }, [setSticky])
  useEffect(() => {
    window.addEventListener("scroll", handleSticky)
    return () => window.removeEventListener("scroll", handleSticky)
  }, [handleSticky])

  const url_logo = data?.wp?.siteOptions?.acf?.logo?.sourceUrl
  const url_stickyLogo = data?.wp?.siteOptions?.acf?.mobileLogo?.sourceUrl
  const menuItems = data?.wpMenu?.menuItems?.nodes

  return (
    <>
      <div
        className={"header_wrapper"}
        style={
          sticky && !showMenu
            ? {
                backgroundColor: "#003c4c",
                height: width > 992 ? "91px" : "74px",
              }
            : {}
        }
      >
        <div
          className={sticky && !showMenu ? "header is_sticky" : "header"}
          style={
            showMenu
              ? {
                  width: "23%",
                  padding: width >= 390 ? "24px 30px" : "24px 15px",
                }
              : {}
          }
        >
          {!showMenu && (
            <Link to="/">
              <StickyLogo url_stickyLogo={url_stickyLogo} />
              <Logo />
            </Link>
          )}
          {width > 1200 ? (
            <div className="header_nav">
              {menuItems.map((link, index) => {
                if (link.label === 'Contact us') { 
                  return (
                    <button
                      type="button"
                      className="header_nav_btn"
                      key={index}
                      onClick={() => setHide(false)}
                    >
                      {link.label}
                    </button>
                  )
                } else {
                  return (
                    <Link
                      to={link.uri}
                      className="header_nav_btn"
                      key={index}
                    >
                      {link.label}
                    </Link>
                  )
                }
              })}
            </div>
          ) : (
            <button
              className="header_menu_btn"
              onClick={() => {
                setShowMenu(true)
                setHide(true)
              }}
            >
              <Hambuger />
            </button>
          )}
        </div>
      </div>
    </>
  )
}
export default Header
