import React from "react"

function Hambuger() {
  return (
    <svg
      id="_i_Menu"
      data-name="/i/Menu"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="13.6"
      viewBox="0 0 32 13.6"
    >
      <g id="Group_7" data-name="Group 7">
        <rect id="Rectangle" width="32" height="2.8" fill="#fff" />
        <rect
          id="Rectangle_Copy"
          data-name="Rectangle Copy"
          width="32"
          height="2.8"
          transform="translate(0 10.8)"
          fill="#fff"
        />
      </g>
    </svg>
  )
}

export default Hambuger
