import React from "react"
import Logo from "../header/images/Logo"
import Close from "./images/Close"
import { Link } from "gatsby"

function Menu(props) {
  const { setShowMenu, links, handleCheckNav,showMenu, setHide } = props
  const Socials = [
    { name: "LinkedIn", to: "http://www.linkedin.com" },
    { name: "Instagram", to: "http://www.instagram.com" },
  ]
  const Contacts = [
    { name: "Call us", content: "0431 777 450", type: "phone" },
    { name: "Email", content: "info@finney.com.au", type: "mail" },
  ]
  return (
    <div className={showMenu ? "menu active_menu" : "menu"}>
      <div className="menu_wrapper">
        <div className="menu_header">
          <div className="menu_header_logo">
            <Logo />
          </div>
          <div
            aria-hidden="true"
            className="menu_close_btn"
            onClick={() => {
              setShowMenu(false)
            }}
          >
            <Close />
          </div>
        </div>
        <div className="menu_links">
          {links.map((link, index) => {
            if (link.label === 'Contact us') { 
              return (
                <button
                  type="button"
                  key={index}
                  onClick={() => {
                    setHide(false)
                    setShowMenu(false)
                  }}
                >
                  {link.label}
                </button>
              )
            } else {
              return (
                <Link
                  to={link.uri}
                  key={index}
                  onClick={() => {
                    setShowMenu(false)
                  }}
                >
                  {link.label}
                </Link>
              )
            }
          })}
        </div>
        <div className="menu_socials">
          {Socials.map((social, index) => {
            return (
              <Link to={social.to} key={index}>
                {social.name}
              </Link>
            )
          })}
        </div>
        <div className="menu_contacts">
          {Contacts.map((contact, index) => {
            return (
              <p key={index}>
                {contact.name} <span>— </span>
                <a
                  href={
                    contact.type === "mail"
                      ? `mailto:${contact.content}`
                      : `tel:${contact.content}`
                  }
                >
                  {contact.content}
                </a>
              </p>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Menu
