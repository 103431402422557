import React from "react"

function StickyLogo({url_stickyLogo}) {
  return (
    <svg
      id="Wordmark"
      xmlns={url_stickyLogo}
      width="66.978"
      height="12"
      viewBox="0 0 66.978 12"
    >
      <g
        id="finneyAsset_1"
        data-name="finneyAsset 1"
        transform="translate(0.267 0.097)"
      >
        <path
          id="Shape"
          d="M39.229,11.9l-5.7-7.564V11.9H30.973V0h2.389l5.514,7.327V0h2.555V11.9Zm20.279,0V7.208L54.985,0h3.023l2.81,4.778L63.677,0l2.945.005L62.1,7.163V11.9Zm-14.914,0V0h8.88V2.335H47.166V4.744h5.543V7.072H47.166v2.5h6.39V11.9Zm-18.985,0-5.694-7.56V11.9H17.354V0h2.381l5.522,7.327V0h2.555V11.9Zm-14.123,0V0h2.589V11.9ZM0,11.9V0H8.964V2.381H2.591V4.913H8.2V7.294H2.591V11.9Z"
          fill="#ffffff"
        />
      </g>
    </svg>
  )
}

export default StickyLogo
