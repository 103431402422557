import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Footer_content_top from './footer_content_top/footer_content_top'
import Footer_content_bottom from './footer_content_bottom/footer_content_bottom'
export default function Footer({setHide}){
  const data = useStaticQuery(graphql`
  query  {
    wp {
      siteOptions {
        acf {
          footerLogo {
            sourceUrl
          }
          address
          email
          phone
          slogan
        }
      }
    }
  }
  `)
    return (
      <footer className='footer'>
        <div className='footer__inner'>
          <Footer_content_top data={data} />
          <Footer_content_bottom/>
        </div>
      </footer>
    )
}
