import { Link } from "gatsby"
import React from "react"
import Logo_finney from "../logo/logo"
export default function Footer_content_top({ data }) {
  const data_footer = data?.wp?.siteOptions?.acf
  const content_slogan = data_footer?.slogan.split("\n,")
  const footer_log_url = data_footer?.footerLogo?.sourceUrl
  const address = data_footer?.address
  return (
    <div className="footer_top">
      <div className="footer_top_inner">
        <div className="footer_top_content">
          <div className="content_footer">
            <div className="footer_logo">
              <Link to="/">
                {" "}
                <Logo_finney footer_log_url={footer_log_url} />
              </Link>
              <div className="footer_information">
                <div className="information_left">
                  <div>
                    <p>
                      <span>Call us</span> —{" "}
                      <a href="tel:0413777450">{data_footer.phone}</a>
                    </p>
                    <p>
                      <span>Email</span> —
                      <i>
                        <a href="mailto:admin@finney.net.au">
                          admin@finney.net.au
                        </a>
                      </i>
                    </p>
                  </div>

                  <div dangerouslySetInnerHTML={{ __html: address }}></div>
                </div>

                <div className="information_right">
                  <p>
                    <Link to='https://www.instagram.com/finneyconstruction/'>Instagram</Link><br />
                    <Link to='https://www.pinterest.com.au/finneyconstruct/'>Pinterest</Link><br />
                    <Link to='https://www.houzz.com.au/professionals/home-builders/finney-construction-pfvwau-pf~344753945?irs=US'>Houzz</Link><br />
                    <Link to='https://www.facebook.com/finney.melbourne/'>Facebook</Link>
                  </p>
                </div>
              </div>
            </div>
            <div
              className="content_recommend"
              dangerouslySetInnerHTML={{ __html: content_slogan }}
            ></div>
          </div>
        </div>
        <hr />
      </div>
    </div>
  )
}
