import React from "react"

function Logo() {
  return (
    <>
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 79 100" >
        <polygon points="29.7,93.4 23.2,84.8 20.4,84.8 20.4,98.7 23.4,98.7 23.4,89.9 30.2,98.7 32.7,98.7 32.7,84.8 
          29.7,84.8" fill="#FFFFFF"/>
        
          <rect fill="#FFFFFF" x="13.5" y="84.8" transform="matrix(1 -1.320765e-03 1.320765e-03 1 -0.1212 1.994646e-02)" class="st0" width="3" height="13.9"/>
        <polygon fill="#FFFFFF" points="0,98.7 3,98.7 3,93.3 9.6,93.3 9.6,90.6 3,90.6 3,87.6 10.5,87.6 10.5,84.8 0,84.8 	"/>
        <polygon fill="#FFFFFF" points="45.8,93.4 39.3,84.8 36.5,84.8 36.5,98.7 39.5,98.7 39.5,89.9 46.2,98.7 48.8,98.7 48.8,84.8 
          45.8,84.8 	"/>
        <polygon fill="#FFFFFF" points="55.5,93.1 62.1,93.1 62.1,90.4 55.5,90.4 55.5,87.6 63,87.6 63,84.8 52.5,84.8 52.5,98.7 63.1,98.7 
          63.1,96 55.5,96 	"/>
        <polygon fill="#FFFFFF" points="75,84.8 71.6,90.4 68.3,84.8 64.8,84.8 70.1,93.2 70.1,98.7 73.1,98.7 73.1,93.2 78.5,84.8 	"/>
        <polygon fill="#FFFFFF" points="25,50.2 48.2,50.2 48.2,33.2 25,33.2 25,20.3 47.9,20.3 47.9,17.1 21.7,17.1 21.7,36.5 45,36.5 
          45,46.9 21.7,46.9 21.7,73.7 3.2,73.7 3.2,4.4 45,4.4 45,9.1 7.1,9.1 7.1,69.7 17.5,69.7 17.5,17.4 14.2,17.4 14.2,66.4 10.4,66.4 
          10.4,12.4 48.2,12.4 48.2,1.1 0,1.1 0,77 25,77"/>
      </svg>
    </>
  )
}

export default Logo
