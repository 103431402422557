import { Link } from 'gatsby'
import React from 'react'

export default function Footer_content_bottom() {
  return (
    <div className='footer_bottom'>
                 <div className='footer_bottom_inner'>
            <div className='content_left'>
                   <p>
                   © Finney 2024. All Rights Reserved.
                   </p>
                   <p>&nbsp;</p>
            </div>
            <div className='content_right'>

            </div>
                 </div>
          </div>
  )
}
