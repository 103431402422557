import React, { useState } from 'react'
import Logo from '../header/images/Logo'
import { useStaticQuery, graphql } from "gatsby"
import Link from '../../utils/link'

function encode(data) {
  return Object.keys(data).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])).join('&');
}

export default function Contact({ hide,setHide }) {
  const data = useStaticQuery(graphql`
  query  {
    wp {
      siteOptions {
        acf {
          contactMessage
          phone
          email
          linkedin
          instagram
        }
      }
    }
  }
  `)
  const data_contact = data?.wp?.siteOptions?.acf
  const messenger=data_contact?.contactMessage
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")
  const [hideForm, setHideForm] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    
    fetch(`https://hooks.zapier.com/hooks/catch/3298056/3vaqp55/`, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        'first_name': firstName,
        'last_name': lastName,
        'email': email,
        'phone': phone,
        'message': message
      })
    }).then(() => {
      // Successfully submitted
      setHideForm(true)
    }).catch(error => {
      setHideForm(false)
    })
  }
  const props = {
    name: 'contact',
    onSubmit: handleSubmit
  }
  return (
    <div className={hide === false ? 'wrap_contact active_contact' : "wrap_contact"}>
      <div className='box_fold'>
        <div className='close' onClick={() => setHide(true)}>
          <p>CLOSE</p>
        </div> 
        <div className='wrap_contact__logo'>
          <Logo />
        </div>
        {
          hideForm === false ?
            <div>
              <div className='content_top'>
              </div>
              <div className='content_information'>
                <div className='content_left'>
                  <div className='text_info_top'>
                    <p>
                      <span>Call us</span> —<a href='tel:0413777450'> {data_contact.phone}</a>
                    </p>
                    <p>
                      <span> Email</span> — <i><a href='mailto:info@finney.com.au'>{data_contact.email}</a></i>
                    </p>
                  </div>
                  <div className='text_info_bot'>
                    <p>
                      131 Gardenvale Rd
                    </p>
                    <p>
                      Gardenvale 3185
                    </p>
                  </div>
                </div>
                <div className='content_right'>
                  <p>
                    <Link to={data_contact.linkedin}>LinkedIn</Link>
                  </p>
                  <p>
                    <Link to={data_contact.instagram}>  Instagram</Link>
                  </p>
                </div>
              </div>
              <div className='content_form'>
                <form {...props}>
                  <div className='info_client'>
                    <div className='info_client_left'>
                      <div className='input_text'>
                        <p>First Name</p>
                        <input
                          type='text'
                          name='first_name'
                          onChange={(e) => setFirstName(e.target.value)}
                          required
                        />
                      </div>
                      <div className='input_text'>
                        <p>Last Name</p>
                        <input
                          type='text'
                          name='last_name'
                          onChange={(e) => setLastName(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className='info_client_right'>
                      <div className='input_text'>
                        <p>Email Address</p>
                        <input
                          type='email'
                          name='email'
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>
                      <div className='input_text'>
                        <p>Contact Number</p>
                        <input
                          type='text'
                          name='phone'
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='your_enquiry'>
                    <textarea 
                      name='message' 
                      placeholder='Your Enquiry'
                      onChange={(e) => setMessage(e.target.value)}
                    />
                    <br />
                    <button type='submit'>
                      Send Enquiry
                    </button>
                  </div>
                </form>
              </div>
            </div> :
            <div>
              <div className='form_thanks'>
                <div className='section'>
                  <p>Submitted</p>
                </div>
                <div className='title'>
                  <p>
                    Thanks for your enquiry.
                  </p>
                </div>
                <div className='description' dangerouslySetInnerHTML={{ __html: messenger }}>
                </div>
              </div>
            </div>
        }
      </div>
    </div>
  )
}
